@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/scss/variables.scss';
@import './assets/scss/css-reset.scss';
@import './assets/scss/ng-material-override.scss';
@import './assets/scss/spinner.scss';
@import './assets/scss/utlis.scss';
@import '~prismjs/plugins/line-numbers/prism-line-numbers.css';
@import './assets/scss/code-editors-override.scss';
@import './assets/scss/prism-theme.scss';
@import './assets/scss/quill-override.scss';
@import './assets/scss/flow-builder.scss';
/* Core Data Grid CSS */
@import '~ag-grid-community/styles/ag-grid.css';
/* Theme Specific CSS */
@import '~ag-grid-community/styles/ag-theme-alpine.css'; /* or any other theme you prefer */

:root {
  --title: #{$title};
  --line: #{$line};
  --header: #{$header};
  --dividers: #{$dividers};
  --body: #{$body};
  --border: #{$border};
  --white: #{$white};
  --gray-card: #{$gray-card};
  --placeholder: #{$placeholder};
  --form-label: #{$form-label};
  --black: #{$black};
  --disable: #{$disable};
  --sidebar: #{$sidebar};
  --hover: #{$hover};
  --avatar: #{$avatar};
  --blue-link: #{$blue-link};
  --gray-select: #{$gray-select};
  --add-agent: #{$add-agent};
  --outline: #{$outline};
  --description: #{$description};
  --bleached-gray: #{$bleached-gray};
  --danger: #{$danger};
  --selection: #{$selection-color};

  @each $shadeName, $shadeValue in $primary-color {
    --primary-#{$shadeName}: #{$shadeValue};
  }

  @each $shadeName, $shadeValue in $warn-color {
    --warn-#{$shadeName}: #{$shadeValue};
  }

  @each $shadeName, $shadeValue in $success-color {
    --success-#{$shadeName}: #{$shadeValue};
  }
}
